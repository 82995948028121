export type StyleChange = {
  bottom: boolean
  internal?: boolean
  top: boolean
}

export type ResponsiveStyleChange = {
  desktop: StyleChange
  mobile: StyleChange
}


export const splitStyles = (styles: string | undefined, index: 0 | 1) => {
  const splitStyles = styles?.split(`,`) ?? []
  return splitStyles.length === 1
    ? splitStyles[0]
    : splitStyles[index]
}

export const getAlignmentClass = (alignment: Queries.Maybe<string>) => {
  switch(alignment) {
    case "Center":
      return `text-center`
    case "Right":
      return `text-right`
    case "Left":
    default:
      return `text-left`
  }
}

export const getDesktopSizeClass = (size: Queries.Maybe<string>) => {
  switch(size) {
    case "10%":
      return `sm:w-[10%]`
    case "20%":
      return `sm:w-[20%]`
    case "30%":
      return `sm:w-[30%]`
    case "40%":
      return `sm:w-[40%]`
    case "50%":
      return `sm:w-1/2`
    case "60%":
      return `sm:w-[60%]`
    case "70%":
      return `sm:w-[70%]`
    case "80%":
      return `sm:w-[80%]`
    case "90%":
      return `sm:w-[90%]`
    case "100%":
      return `sm:w-full`
    default:
      return `sm:w-[60%]`
  }
}

export const getMobileSizeClass = (size: Queries.Maybe<string>) => {
  switch(size) {
    case "10%":
    case "20%":
    case "30%":
    case "40%":
    case "50%":
      return `w-1/2`
    case "60%":
    case "70%":
    case "80%":
    case "90%":
      return `w-[calc(100%-2.5rem)]`
    case "100%":
      return `w-full`
    default:
      return `w-[calc(100%-2.5rem)]`
  }
}

export const getDesktopSizeNumber = (size: Queries.Maybe<string>) => {
  return (size ?? `60%`).replace(`%`, `vw`)
}

export const getMobileSizeNumber = (size: Queries.Maybe<string>) => {
  switch(size) {
    case "10%":
    case "20%":
    case "30%":
    case "40%":
    case "50%":
      return `(50vw)`
    case "60%":
    case "70%":
    case "80%":
    case "90%":
      return `(100vw - 2.5rem)`
    case "100%":
      return `(100vw)`
    default:
      return `(100vw - 2.5rem)`
  }
}

const isCarousel = (slice: Queries.PrismicSliceType): slice is Queries.PrismicPageDataBodyCarousel => slice?.slice_type === `carousel`
const isContentGroup = (slice: Queries.PrismicSliceType): slice is Queries.PrismicPageDataBodyContentGroup => slice?.slice_type === `content_group`
const isMetadata = (slice: Queries.PrismicSliceType): slice is Queries.PrismicPageDataBodyMetadata => slice?.slice_type === `metadata`
const isRichText = (slice: Queries.PrismicSliceType): slice is Queries.PrismicPageDataBodyRichText => slice?.slice_type === `rich_text`

export const getSliceStyle = (slices: Queries.PrismicSliceType[], index: number, direction: -1 | 0 | 1 = 0): string | undefined => {
  let sliceStyles,
    sliceStyle = undefined

  // If there is no requested slice, there’s no requested slice style
  if(index + direction < 0 || index + direction > slices.length - 1) return undefined
  
  // Otherwise get the slice
  const slice = slices[index + direction]
  
  // If it’s rich text currently it has no slice style (to be deleted)
  // if(isRichText(slice)) sliceStyle = undefined

  // If it’s metadata, try to get the next slice style
  if(isMetadata(slice)) sliceStyle = getSliceStyle(slices, index + direction, direction)

  // If it’s a carousel slice style is on primary
  if(isCarousel(slice)) sliceStyle = slice?.primary?.background?.toLowerCase().replace(` `, `-`) ?? `warm-gray`

  // If it’s a content group slice style is on items and may need to be concatenated
  if(isContentGroup(slice)) {
    sliceStyles = slice?.items?.map(i => i?.background?.toLowerCase().replace(` `, `-`) ?? `warm-gray`),
    sliceStyle = sliceStyles.length === 1 || sliceStyles[0] === sliceStyles[1]
      ? sliceStyles[0]
      : `${sliceStyles[0]},${sliceStyles[1]}`
    }

  return sliceStyle
}