import React, { useEffect, useRef, useState } from "react"
import { SliceComponentProps } from "@prismicio/react"
import { getDesktopSizeNumber, getMobileSizeNumber, getSliceStyle, splitStyles } from "../lib/contentHelpers"

import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { getDesktopSizeClass, getMobileSizeClass, ResponsiveStyleChange } from "../lib/contentHelpers"

import { useInView } from "framer-motion"

export const Carousel = ({ slice, index, slices, context }: SliceComponentProps<Queries.PrismicPageDataBodyCarousel>) => {
  const sliceStyle = getSliceStyle(slices as Queries.PrismicSliceType[], index, 0),
    previousSliceStyle = getSliceStyle(slices as Queries.PrismicSliceType[], index, -1),
    nextSliceStyle = getSliceStyle(slices as Queries.PrismicSliceType[], index, 1),
    styleChange = {
      desktop: {
        bottom: sliceStyle !== nextSliceStyle,
        top: previousSliceStyle !== sliceStyle,
      },
      mobile: {
        bottom: splitStyles(sliceStyle, 1) !== splitStyles(nextSliceStyle, 0),
        internal: sliceStyle ? sliceStyle?.indexOf(`,`) > -1 : undefined,
        top: splitStyles(previousSliceStyle, 1) !== splitStyles(sliceStyle, 0),
      }
    }

  // console.log(index, sliceStyle, styleChange)

  return (
    <CarouselWrapper
      index={index}
      slice={slice}
      style={sliceStyle}
      styleChange={styleChange}
    />
  )
}

export const CarouselWrapper = ({
  index,
  slice,
  style,
  styleChange,
}: {
  index: number,
  slice: Queries.PrismicPageDataBodyCarousel,
  style?: string,
  styleChange: ResponsiveStyleChange
}) => {
  const [activeIndex, setActiveIndex] = useState(0),
    carouselRef = useRef<HTMLDivElement>(null),
    timerRef = useRef<NodeJS.Timer | undefined>(undefined),
    inView = useInView(carouselRef)

  const length = slice?.items?.length ?? 0,
    incrementIndex = (currentIndex: number) => currentIndex + 1 > length - 1 ? 0 : currentIndex + 1,
    speed = parseInt(slice?.primary?.speed ?? `8`, 10) * 1000

  const dataViewport = slice?.primary?.height ?? false,
    viewportHeight = dataViewport,
    dataMobileViewport = slice?.primary?.mobile_height ?? false,
    mobileViewportHeight = dataMobileViewport,
    desktopSizeClasses = slice?.items?.map(item => getDesktopSizeClass(item?.size)),
    // Treat carousel as full width if any item is full width
    fullWidth = desktopSizeClasses.some(size => size === `sm:w-full`),
    fullBleed = viewportHeight && fullWidth,
    mobileHeightClass = mobileViewportHeight
      ? `h-screen`
      : `h-max`,
    mobileHeightInnerClass = mobileViewportHeight
      ? index === 0
        ? `h-[calc(100vh-var(--header-height-mobile))] md:h-auto`
        : `h-full md:h-auto`
      : ``,
    heightClass = viewportHeight
      ? `md:h-screen`
      : `md:h-auto`

  const pt = {
    no: `pt-0`,
    sm: `pt-4`,
    lg: `pt-12`,
    md: {
      no: `md:pt-0`,
      sm: `md:pt-12`,
      lg: `md:pt-36`,
    }
  },
  pb = {
    no: `pb-0`,
    sm: `pb-4`,
    lg: `pb-12`,
    md: {
      no: `md:pb-0`,
      sm: `md:pb-12`,
      lg: `md:pb-36`,
    }
  },
  paddingClassObject = {
    mobile: {
      // There is no full bleed on mobile after removal of viewport height images
      top: fullBleed
        ? pt.no
        : styleChange.mobile.top
          ? pt.lg
          : pt.sm,
      // There is no full bleed on mobile after removal of viewport height images
      bottom: fullBleed
        ? pb.no
        : styleChange.mobile.bottom
          ? pb.lg
          : pb.sm
    },
    desktop: {
      top: viewportHeight
        ? pt.md.no
        : styleChange.desktop.top
          ? pt.md.lg
          : pt.md.sm,
      bottom: viewportHeight
        ? pb.md.no
        : styleChange.desktop.bottom
          ? pb.md.lg
          : pb.md.sm,
    }
  },
  paddingClasses = `${paddingClassObject.mobile.top} ${paddingClassObject.desktop.top} ${paddingClassObject.mobile.bottom} ${paddingClassObject.desktop.bottom}`

  const itemDimensions = slice?.items?.map(item => {
      const dimensions = item?.image?.dimensions,
        ratio = dimensions?.height && dimensions?.width
          ? dimensions.height / dimensions.width
          : 0,
        desktopWidth = getDesktopSizeNumber(item?.size),
        mobileWidth = getMobileSizeNumber(item?.size)
      return {
        ratio,
        desktopHeight: `${ratio} * ${desktopWidth}`,
        desktopWidth,
        mobileHeight: `${ratio} * ${mobileWidth}`,
        mobileWidth
      }
    }),
    mobileImageHeights = itemDimensions.map(dimensions => dimensions.mobileHeight).join(`, `),
    desktopImageHeights = itemDimensions.map(dimensions => dimensions.desktopHeight).join(`, `)

  useEffect(() => {
    clearInterval(timerRef.current)
    if(inView) timerRef.current = setInterval(() => setActiveIndex(incrementIndex), speed)
    return () => clearInterval(timerRef.current)
  }, [incrementIndex, inView, speed])

  return (
    <div
      className={`carousel w-full ${mobileHeightClass} ${heightClass} ${paddingClasses} overflow-hidden`}
      data-active={activeIndex}
      data-slice={index}
      data-style={style}
      data-viewport={dataViewport}
      data-mobile-viewport={dataMobileViewport}
      id={slice?.primary?.id ?? undefined}
      ref={carouselRef}
    >
      {!dataMobileViewport && (
        <style dangerouslySetInnerHTML={{ __html: `
          @media (max-width: 767px) {
            .carousel[data-slice="${index}"] > .carousel-inner {
              height: max(${mobileImageHeights});
            }
          }
        ` }} />
      )}
      {!dataViewport && (
        <style dangerouslySetInnerHTML={{ __html: `
          @media (min-width: 768px) {
            .carousel[data-slice="${index}"] > .carousel-inner {
              height: max(${desktopImageHeights});
            }
          }
        ` }} />
      )}
      <div
        className={`carousel-inner relative w-full ${mobileHeightInnerClass} overflow-hidden flex flex-row items-center justify-center`}
      >
        {slice?.items?.map((item, i, a) => {
          return (
            <div
              className={`${i === 0 ? `relative` : `absolute`} ${i === activeIndex ? `opacity-100` : `opacity-0`} inset-0 w-full h-max min-h-full flex items-center justify-center transition-opacity duration-500`}
              key={i}
            >
              <CarouselItem
                index={index}
                item={item}
                viewportHeight={dataViewport}
                mobileViewportHeight={dataMobileViewport}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const CarouselItem = ({
  index,
  item,
  viewportHeight,
  mobileViewportHeight
}: {
  item: Queries.PrismicPageDataBodyCarouselItem,
  index: number,
  viewportHeight: boolean
  mobileViewportHeight: boolean
}) => {
  const imageData = item?.image && getImage(item.image as unknown as ImageDataLike),
    mobileSizeClass = getMobileSizeClass(item?.size),
    desktopSizeClass = getDesktopSizeClass(item?.size),
    mobileSizeNumber = getMobileSizeNumber(item?.size),
    desktopSizeNumber = getDesktopSizeNumber(item?.size),
    fullWidth = desktopSizeClass === `sm:w-full`,
    fullBleed = viewportHeight && fullWidth,
    fullBleedClass = fullBleed ? `md:h-full` : `md:h-auto`,
    mobileFullWidth = mobileSizeClass === `w-full`,
    mobileFullBleed = mobileViewportHeight && mobileFullWidth,
    mobileFullBleedClass = mobileFullBleed ? `h-full` : `h-auto`,
    hasData = imageData,
    // If a mobile viewport height carousel is the first slice it needs to leave room for the header
    mobileHeightClass = mobileViewportHeight
      ? index === 0
        ? `h-[calc(100vh-var(--header-height-mobile))]`
        : `h-full`
      : ``,
    heightClass = viewportHeight
      ? `md:h-screen`
      : `md:h-auto`

  // Calculate image sizes
  const dimensions = item?.image?.dimensions,
    inverseRatio = dimensions?.height && dimensions?.width
      ? dimensions.width / dimensions.height
      : 1,
    // Mobile sizes
    mobileSizes = mobileFullBleed
      // If full bleed, width = image width / image height * carousel height
      ? `calc(${inverseRatio} * ${index === 0 ? `(100vh - var(--header-height-mobile))` : `100vh`})`
      // Otherwise, same as content item
      : `calc(${mobileSizeNumber})`,
    // Desktop sizes
    desktopSizes = fullBleed
      // If full bleed, image width / image height * carousel height
      ? `calc(${inverseRatio} * 100vh)`
      // Otherwise, same as content item
      : `(min-width: 640px) ${desktopSizeNumber}`,
    // Combine sizes for render
    sizes = `${mobileSizes}, ${desktopSizes}`

  return (
    <div className={`${!hasData ? `hidden only:flex sm:flex` : `flex`} flex-col items-center justify-center basis-full ${mobileHeightClass} ${heightClass}`}>
      <div className={`relative group w-full ${mobileHeightClass} md:h-full flex flex-col items-center justify-center`}>
        {imageData && (
          <div className={`${mobileSizeClass} ${desktopSizeClass} ${mobileFullBleedClass} ${fullBleedClass}`}>
              <GatsbyImage
                alt={item?.image?.alt ?? ``}
                className={`w-full ${mobileFullBleedClass} ${fullBleedClass}`}
                image={imageData}
                sizes={sizes}
              />
          </div>
        )}
      </div>
    </div>
  )
}