import { SliceComponentProps } from "@prismicio/react"
import { ContentItem } from "../components/ContentItem"
import { getImage, ImageDataLike } from "gatsby-plugin-image"
import { getSliceStyle, splitStyles, ResponsiveStyleChange } from "../lib/contentHelpers"
import React from "react"

export const ContentGroup = ({ slice, index, slices, context }: SliceComponentProps<Queries.PrismicPageDataBodyContentGroup>) => {
  const sliceStyle = getSliceStyle(slices as Queries.PrismicSliceType[], index, 0),
    previousSliceStyle = getSliceStyle(slices as Queries.PrismicSliceType[], index, -1),
    nextSliceStyle = getSliceStyle(slices as Queries.PrismicSliceType[], index, 1),
    styleChange = {
      desktop: {
        bottom: sliceStyle !== nextSliceStyle,
        top: previousSliceStyle !== sliceStyle,
      },
      mobile: {
        bottom: splitStyles(sliceStyle, 1) !== splitStyles(nextSliceStyle, 0),
        internal: sliceStyle ? sliceStyle?.indexOf(`,`) > -1 : undefined,
        top: splitStyles(previousSliceStyle, 1) !== splitStyles(sliceStyle, 0),
      }
    }

  return <ContentWrapper index={index} slice={slice} style={sliceStyle} styleChange={styleChange} />
}

const ContentWrapper = ({
  index,
  slice,
  style,
  styleChange,
}: {
  index: number,
  slice: Queries.PrismicPageDataBodyContentGroup,
  style?: string,
  styleChange: ResponsiveStyleChange,
}) => {
  const dataViewport = slice?.primary?.height ?? false

  const itemsImageData = slice.items.map(item => item?.image && getImage(item.image as unknown as ImageDataLike)),
    itemsTextData = slice.items.map(item => item?.text?.richText),
    itemHasText = itemsTextData.map(textData => textData.length > 0 && textData[0].text !== ``),
    itemHasData = slice.items.map((item, i) => itemHasText[i] || itemsImageData[i]),
    itemsWithData = itemHasData.filter(v => v === true)

  return (
    <div
      className={`relative w-full ${dataViewport ? `md:h-screen` : ``} flex flex-col md:flex-row`}
      data-slice={index}
      data-style={style}
      data-viewport={dataViewport}
      id={slice?.primary?.id ?? undefined}
    >
      {slice?.items?.map((item, i, a) => {
        if(i > 1) return null
        return (
          <ContentItem
            key={i}
            index={i}
            item={item}
            hasSibling={a.length > 1}
            mobileHasSibling={itemsWithData.length > 1}
            styleChange={styleChange}
            viewportHeight={dataViewport}
          />)
      })}
    </div>
  )
}