import React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { getAlignmentClass, getDesktopSizeClass, getDesktopSizeNumber, getMobileSizeClass, getMobileSizeNumber, ResponsiveStyleChange } from "../lib/contentHelpers"

export const ContentItem = ({
  item,
  index,
  hasSibling,
  mobileHasSibling,
  styleChange,
  viewportHeight
}: {
  item: Queries.PrismicPageDataBodyContentGroupItem,
  index: number,
  hasSibling: boolean,
  mobileHasSibling: boolean,
  styleChange: ResponsiveStyleChange,
  viewportHeight: boolean
}) => {
  const basisClass = hasSibling ? `basis-1/2` : `basis-full` ,
    dataStyle = item?.background?.toLowerCase().replace(` `, `-`) ?? `warm-gray`,
    imageData = item?.image && getImage(item.image as unknown as ImageDataLike),
    textData = item?.text?.richText,
    textAlignmentClass = getAlignmentClass(item?.alignment),
    mobileSizeClass = getMobileSizeClass(item?.size),
    desktopSizeClass = getDesktopSizeClass(item?.size),
    mobileSizeNumber = getMobileSizeNumber(item?.size),
    desktopSizeNumber = getDesktopSizeNumber(item?.size),
    sizes = hasSibling
      // hasSibling:
      // 1) default: Siblings are displayed vertically at mobile size, use full mobile size
      // 2) `sm` / 640px: Siblings are displayed vertically at full width, use full desktop size,
      // 3) `md` / 768px: Siblings are displayed horizontally at 50% width, use half desktop size
      ? `calc(${mobileSizeNumber}), (min-width: 640px) ${desktopSizeNumber}, (min-width: 768px) calc(0.5 * ${desktopSizeNumber})`
      : `calc(${mobileSizeNumber}), (min-width: 640px) ${desktopSizeNumber}`,
    fullWidth = desktopSizeClass === `sm:w-full`,
    fullBleed = viewportHeight && fullWidth,
    fullBleedClass = fullBleed ? `md:h-full` : ``,
    hasText = textData.length > 0 && textData[0].text !== ``,
    hasData = hasText || imageData,
    textHover = item?.visibility,
    textPositionClass = imageData
      ? textHover
        // Hover effect only for primary input pointer devices, and hidden on mobile
        ? `hidden sm:mouse:flex fixed z-10 pointer-events-none inset-0 w-screen h-screen items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500`
        : `mt-2`
      : ``,
    pt = {
      no: `pt-0`,
      sm: `pt-4`,
      lg: `pt-12`,
      md: {
        no: `md:pt-0`,
        sm: `md:pt-12`,
        lg: `md:pt-36`,
      }
    },
    pb = {
      no: `pb-0`,
      sm: `pb-4`,
      lg: `pb-12`,
      md: {
        no: `md:pb-0`,
        sm: `md:pb-12`,
        lg: `md:pb-36`,
      }
    },
    paddingClassObject = {
      mobile: {
        top: fullBleed
          ? pt.no
          : (index === 0 && styleChange.mobile.top)
            || (index === 1 && (mobileHasSibling ? styleChange.mobile.internal : styleChange.mobile.top))
            ? pt.lg
            : pt.sm,
        bottom: fullBleed
          ? pb.no
          : (index === 0 && (mobileHasSibling ? styleChange.mobile.internal : styleChange.mobile.bottom)) || (index === 1 && styleChange.mobile.bottom)
            ? pb.lg
            : pb.sm
      },
      desktop: {
        top: viewportHeight
          ? pt.md.no
          : styleChange.desktop.top
            ? pt.md.lg
            : pt.md.sm,
        bottom: viewportHeight
        ? pb.md.no
        : styleChange.desktop.bottom
          ? pb.md.lg
          : pb.md.sm,
      }
    },
    paddingClasses = `${paddingClassObject.mobile.top} ${paddingClassObject.desktop.top} ${paddingClassObject.mobile.bottom} ${paddingClassObject.desktop.bottom}`

  return (
    <div className={`${!hasData ? `hidden only:flex sm:flex` : `flex`} flex-col items-center justify-center ${basisClass} ${paddingClasses}`} data-style={dataStyle} data-item={index}>
      <div className="relative group w-full h-full flex flex-col items-center justify-center">
        {imageData && (
          <div className={`${mobileSizeClass} ${desktopSizeClass} ${fullBleedClass}`}>
              <GatsbyImage
                alt={item?.image?.alt ?? ``}
                className={`w-full ${fullBleedClass}`}
                image={imageData}
                sizes={sizes}
              />
          </div>
        )}
        {hasText && (
          <div className={`${imageData && mobileSizeClass === `w-1/2` ? `w-1/2` : `w-[calc(100%-2.5rem)]`} ${desktopSizeClass}`}>
            <div className={`w-full ${fullWidth ? `sm:px-3` : ``} ${textPositionClass} ${textAlignmentClass}`}>
              <PrismicRichText field={textData} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}