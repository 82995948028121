import { PrismicRichText } from "@prismicio/react"
import React from "react"

export const RichText = ({ slice }: any) => {
  let widthClass,
    alignmentClass

  switch(slice?.primary?.width) {
    case "Extra Small":
      widthClass = `w-[500px]`
      break
    case "Medium":
      widthClass = `w-[700px]`
      break
    case "Large":
      widthClass = `w-[800px]`
      break
    case "Extra Large":
      widthClass = `w-[900px]`
      break
    case "Small":
    default:
      widthClass = `w-[600px]`
  }

  switch(slice?.primary?.alignment) {
    case "Center":
      alignmentClass = `text-center`
      break
    case "Right":
      alignmentClass = `text-right`
      break
    case "Left":
    default:
      alignmentClass = `text-left`
  }

  return (
    <div
      className={`${widthClass} ${alignmentClass}`}
      id={slice?.primary?.id ?? undefined}
    >
      <PrismicRichText
        // components={{
        //   paragraph: ({ children, key }) => (
        //     <p key={key} className="mt-5 text-15px sm:text-3xl">
        //       {children}
        //     </p>
        //   ),
        //   hyperlink: ({ node, children, key }) => (
        //     <PrismicLink
        //       key={key}
        //       field={node.data}
        //       className="-m-0.5 p-0.5 underline transition focus:outline-none focus-visible:ring focus-visible:ring-brand hocus:text-brand/50"
        //     >
        //       {children}
        //     </PrismicLink>
        //   ),
        // }}
        field={slice?.primary?.text?.richText}
      />
    </div>
  )
}