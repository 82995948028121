import React, { ReactElement } from "react"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview, useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { SliceLike, SliceZone, SliceZoneLike } from "@prismicio/react"
import { components } from "../slices"

const PageTemplate = (
  props: PageProps<Queries.PageByUidQuery>
): ReactElement => {
  const { data: staticData, ...rest } = props,
    { data } = useMergePrismicPreviewData(staticData),
    document = data.prismicPage,
    slices = document?.data?.body
      ?.filter(s => `slice_type` in s && s.slice_type !== `metadata`)
      ?.filter(s => `items` in s && s.items.length > 0) as SliceZoneLike<SliceLike<string>>

  let children = null
  if(document?.data) {
    children = (
      <>
        {slices && (
          <SliceZone
            slices={slices}
            components={components}
            // context={rest}
          />
        )}
      </>
    )
  }

  return (
    <div
      className="flex flex-col items-center min-h-full"
      data-intersection-targets-root
    >
      {children}
    </div>
  )
}

const PageTemplateWithPreview = withPrismicPreview(PageTemplate)

export default PageTemplateWithPreview

export const pageQuery = graphql`
  query PageByUid($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      data {
        body {
          ... on PrismicPageDataBodyCarousel {
            id
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                dimensions {
                  height
                  width
                }
              }
              size
            }
            primary {
              id
              background
							height
              mobile_height
              speed
            }
            slice_type
          }
          ... on PrismicPageDataBodyContentGroup {
            id
            items {
              alignment
              background
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                dimensions {
                  height
                  width
                }
              }
              size
              text {
                richText
              }
              visibility
            }
            primary {
              id
							height
            }
            slice_type
          }
          ... on PrismicPageDataBodyRichText {
            id
            slice_type
            primary {
              id
              alignment
              text {
                richText
              }
              width
            }
          }
        }
        title
      }
      type
      uid
    }
  }
`
